<template>
    <div id="admin-welcome">
        <i class="fa fa-hand"></i>
        <h4>Welcome to Zyno<span>Suite</span> Admin</h4>
        <p>Choose an item from the left to get started.</p>
        <p>If you need any help, <a href="#" @click.prevent="openSupport">click here</a> to open a support ticket and we'll be in touch shortly.</p>
    </div>
</template>

<script lang="ts" setup>
function openSupport() {
    const openHelpBtn = document.querySelector('#nav-openHelp') as HTMLElement | undefined;
    if (openHelpBtn) openHelpBtn.click();
}
</script>

<style lang="scss" scoped>
#admin-welcome {
    @apply flex-1 flex flex-col items-center justify-center gap-8;

    * {
        @apply text-slate-500;
    }

    h4 {
        @apply font-medium;

        span {
            @apply font-bold;
        }
    }

    a {
        @apply text-blue-500 hover:text-blue-900;
    }
}

.fa-hand {
    @apply text-6xl;
    transform-origin: bottom center;
    animation: wave 3s ease-in-out infinite;
}

@keyframes wave {
    0% {
        transform: rotate(0deg);
    }
    10% {
        transform: rotate(15deg);
    }
    20% {
        transform: rotate(-15deg);
    }
    30% {
        transform: rotate(15deg);
    }
    40% {
        transform: rotate(-15deg);
    }
    50% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
</style>
