import { handleErrorAndAlert, showWait } from '@signal24/vue-foundation';

export interface RequestHelperOptions {
    rethrow?: boolean;
    skipHandleError?: boolean;
    skipUnwaitOnSuccess?: boolean;
}

export async function withErrorHandler<T>(fn: () => Promise<T>, options?: RequestHelperOptions) {
    try {
        return await fn();
    } catch (e) {
        if (!options?.skipHandleError) {
            handleErrorAndAlert(e);
            if (!options?.rethrow) {
                return;
            }
        }
        throw e;
    }
}

export async function withWait<T>(titleOrWait: string | (() => () => void), fn: () => Promise<T>, options?: RequestHelperOptions) {
    const unwait = typeof titleOrWait === 'string' ? showWait(titleOrWait) : titleOrWait();
    let hadSuccess = false;
    try {
        const response = await withErrorHandler(fn, { ...options, rethrow: true });
        hadSuccess = true;
        return response;
    } catch (e) {
        if (!options?.rethrow) {
            return;
        }
        throw e;
    } finally {
        if (!hadSuccess || !options?.skipUnwaitOnSuccess) {
            unwait();
        }
    }
}

// export async function withModalWait<T>(fn: () => Promise<T>, flags?: RequestHelperFlags) {
//     return withWait(
//         () => {
//             const modalEls = [...document.querySelectorAll('.vf-modal')];
//             const lastModalEl = modalEls[modalEls.length - 1];
//             if (!lastModalEl) return () => {};

//             const modalFns = (lastModalEl as any).__vnode.ctx.exposed as InstanceType<typeof VfModal>;
//             return modalFns.mask();
//         },
//         fn,
//         flags
//     );
// }
