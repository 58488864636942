<template>
    <div id="nav">
        <RouterLink to="/" class="logo">
            Zyno<strong>{{ rootStore.rootAppName ?? 'Suite' }}</strong>
            <template v-if="rootStore.subAppName">&nbsp;{{ rootStore.subAppName }}</template>
        </RouterLink>

        <div id="#tab-bar" class="flex-1"></div>

        <div class="qab">
            <i id="nav-openHelp" v-tooltip="'Help'" class="fa-light fa-comment-question" @click="openHelp"></i>
            <i
                v-if="updateAvailable"
                v-animation="{ cls: 'fa-bounce', gap: 3 }"
                v-tooltip="'Click to update Zyno'"
                class="fa-solid fa-circle-up update-icon"
                @click="doUpdate"
            ></i>
            <User />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { showWait } from '@signal24/vue-foundation';

import { useUpdateAvailableRef } from '@/shared/helpers/update.helpers';
import { useStore } from '@/store';

// import OmniSearch from './omnisearch.vue';
import User from './qab/c-user.vue';

// global keyboard event listener to open omnisearch
// TODO: clean up, add command+F w/ secondary browser forwarding, use proper selector (or singleton)
// window.addEventListener('keydown', e => {
//     if (e.key === 'k' && e.metaKey) {
//         e.preventDefault();
//         const modalWraps = document.querySelectorAll('.vf-modal-wrap');
//         const omnisearchModalWrap = Array.from(modalWraps).find(mw => mw.id === 'omnisearch');
//         if (omnisearchModalWrap) return;
//         openOmnisearch();
//     }
// });

// async function openOmnisearch() {
//     await presentOverlay(OmniSearch, {});
// }

const rootStore = useStore();
const updateAvailable = useUpdateAvailableRef();

function openHelp() {
    const FreshworksWidget = window.FreshworksWidget;
    FreshworksWidget('open');
}

function doUpdate() {
    showWait('Reloading Zyno...');
    location.reload();
}
</script>

<style lang="scss" scoped>
#nav {
    @apply flex justify-between items-center;

    .logo {
        @apply text-white;
    }

    .logo,
    .logo > strong {
        @apply text-2xl;
    }

    .qab {
        @apply flex items-center gap-2;

        i {
            @apply cursor-pointer text-white hover:opacity-50 text-3xl ml-2;
        }

        > :last-child {
            @apply ml-2;
        }
    }
}
</style>

<style lang="scss">
#freshworks-frame-wrapper {
    bottom: inherit !important;
    animation: none !important;
    top: 64px;
}
</style>
