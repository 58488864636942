<template>
    <VfModal id="m-auth-fingerprints" ref="modal">
        <template #header>
            Manage Fingerprints
            <template v-if="account"> for {{ account.firstName }} {{ account.lastName }} </template>
        </template>

        <Loader v-if="!fingerprints" />

        <div v-else class="content">
            <div class="instructions">
                <div v-if="hasSlots">{{ enrollInstructions }}</div>
                <div v-if="hasFingerprints">Click a blue fingerprint to delete.</div>
            </div>

            <div class="hands">
                <div class="hand right-hand">
                    <i class="fa fa-hand" v-tooltip="'Left Hand'" />
                    <div class="fingers">
                        <div
                            v-for="finger in leftFingers"
                            :key="finger.idx"
                            class="finger"
                            v-tooltip="finger.tooltip"
                            :class="{ enrolled: !!finger.fingerprint }"
                            @click="handleFingerClick(finger)"
                        >
                            <i class="fa fa-fingerprint" />
                        </div>
                    </div>
                </div>
                <div class="hand left-hand">
                    <i class="fa fa-hand" v-tooltip="'Right Hand'" />
                    <div class="fingers">
                        <div
                            v-for="finger in rightFingers"
                            :key="finger.idx"
                            class="finger"
                            v-tooltip="finger.tooltip"
                            :class="{ enrolled: !!finger.fingerprint }"
                            @click="handleFingerClick(finger)"
                        >
                            <i class="fa fa-fingerprint" />
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="unassignedFingers.length" @click="deleteUnassigned" class="unassigned">
                {{ account ? `${account.firstName} ${account.lastName} has` : 'You have' }} {{ unassignedFingers.length }}
                {{ unassignedFingers.length === 1 ? 'fingerprint' : 'fingerprints' }} not associated with a particular finger. Click here to remove
                unassociated fingerprints, then re-enroll to a particular finger.
            </div>
        </div>

        <template #footer>
            <button type="button" @click="callback(hadChanges)">Done</button>
        </template>
    </VfModal>
</template>

<script lang="ts" setup>
import { VfModal, presentOverlay, showAlert, showConfirmDestroy, vfModalRef } from '@signal24/vue-foundation';
import { computed, onMounted, ref } from 'vue';

import { GlobalState } from '@/global';
import { type IAccount, type IAccountFingerprint, IdentityApi } from '@/openapi-clients-generated/auth';
import Loader from '@/shared/components/loader.vue';
import { withWait } from '@/shared/helpers/request.helpers';
import { filters } from '@/vf.setup';
import { dataFrom } from '@signal24/openapi-client-codegen/browser';
import { format } from 'date-fns';
import { compact, keyBy } from 'lodash';
import MEnrollFingerprint from './m-enroll-fingerprint.vue';

const modal = vfModalRef();

const props = defineProps<{
    account?: IAccount;
    callback: (didUpdate?: boolean) => void;
}>();

const fingerprints = ref<IAccountFingerprint[]>();
const hadChanges = ref(false);

interface IFinger {
    idx: number;
    label: string;
    tooltip: string;
    fingerprint?: IAccountFingerprint;
}

const fingerLabels = [
    'Left Pinky',
    'Left Ring',
    'Left Middle',
    'Left Index',
    'Left Thumb',
    'Right Thumb',
    'Right Index',
    'Right Middle',
    'Right Ring',
    'Right Pinky'
];

const fingers = computed(() => {
    const fingers: IFinger[] = [];
    const fingerprintsByIdx = keyBy(fingerprints.value ?? [], 'finger');
    for (let idx = 0; idx < 10; idx++) {
        const fingerprint = fingerprintsByIdx[idx];
        fingers.push({
            idx,
            label: fingerLabels[idx],
            fingerprint,
            tooltip: compact([
                `${fingerLabels[idx]}`,
                fingerprint && `Enrolled ${format(new Date(fingerprint.registeredAt), 'yyyy-MM-dd HH:mm')}`,
                fingerprint && `Last used ${format(new Date(fingerprint.registeredAt), 'yyyy-MM-dd HH:mm')}`,
                fingerprint ? 'Click to delete' : 'Click to enroll'
            ]).join('\n')
        });
    }
    return fingers;
});

const leftFingers = computed(() => fingers.value.slice(0, 5));
const rightFingers = computed(() => fingers.value.slice(5));

const hasSlots = computed(() => fingers.value.some(f => !f.fingerprint));
const hasFingerprints = computed(() => fingers.value.some(f => f.fingerprint));
const unassignedFingers = computed(() => fingerprints.value?.filter(f => f.finger === null) ?? []);

const enrollInstructions = computed(() => {
    if (!GlobalState.isInstall) return 'Use Zyno Desktop to enroll fingerprints.';
    if (!GlobalState.installCapabilities.value?.fingerprintReader)
        return 'Attach a fingerprint reader and restart Zyno Desktop to enroll fingerprints.';
    return 'Click a gray fingerprint to enroll.';
});

async function load() {
    if (props.account) {
        fingerprints.value = props.account.fingerprints;
    } else {
        fingerprints.value = dataFrom(await IdentityApi.getIdentityGetFingerprints());
    }
}

async function deleteUnassigned() {
    withWait('Deleting unassigned fingerprints...', async () => {
        const unassignedFingerprintIds = unassignedFingers.value.map(f => f.id);
        for (const fingerprintId of unassignedFingerprintIds) {
            await IdentityApi.deleteIdentityDeleteFingerprints({ path: { id: fingerprintId } });
            fingerprints.value = fingerprints.value?.filter(f => f.id !== fingerprintId);
        }
        hadChanges.value = true;
    });
}

async function handleFingerClick(finger: IFinger) {
    if (finger.fingerprint) {
        const confirmed = await showConfirmDestroy(`Are you sure you want to remove the ${finger.label.toLowerCase()} fingerprint?`);
        if (!confirmed) return;

        return withWait('Deleting fingerprint...', async () => {
            await IdentityApi.deleteIdentityDeleteFingerprints({ path: { id: finger.fingerprint!.id } });
            fingerprints.value = fingerprints.value?.filter(f => f.id !== finger.fingerprint!.id);
        });
    }

    if (!GlobalState.isInstall) return showAlert('Please use Zyno Desktop to enroll fingerprints.');
    if (!GlobalState.installCapabilities.value?.fingerprintReader)
        return showAlert('Please attach a fingerprint reader and restart Zyno Desktop to enroll fingerprints.');

    const result = await presentOverlay(MEnrollFingerprint, {
        account: props.account,
        fingerName: finger.label,
        fingerIdx: finger.idx
    });
    if (!result) return;

    fingerprints.value?.push(result);
    hadChanges.value = true;
}

onMounted(load);
</script>

<style lang="scss" scoped>
.content {
    @apply flex flex-col gap-8 max-w-screen-sm items-center;
}

.instructions {
    @apply text-lg text-gray-400 flex flex-col items-center;
}

.hands {
    @apply flex gap-12;
}

.hand {
    @apply flex flex-col gap-6 items-center;

    .fa-hand {
        @apply text-8xl text-gray-400;
    }

    &.right-hand {
        .fa-hand {
            transform: scaleX(-1);
        }
    }
}

.fingers {
    @apply flex gap-4;
}

.finger {
    @apply text-5xl text-gray-400 cursor-pointer hover:opacity-50;

    &.enrolled {
        @apply text-blue-500;
    }
}

.unassigned {
    @apply border border-red-300 bg-red-50 hover:bg-red-100 text-red-800 rounded-md p-2 cursor-pointer;
}
</style>
