import type { RouteRecordRaw } from 'vue-router';

import { wrapRuntimeImport } from '@/shared/helpers/async.helpers';

export const AdminIamRoutes: RouteRecordRaw[] = [
    {
        path: '',
        redirect: '/admin/iam/users'
    },
    {
        path: 'users',
        component: wrapRuntimeImport(() => import('./screens/users.vue')),
        meta: { admin: true }
    },
    {
        path: 'roles',
        component: wrapRuntimeImport(() => import('./screens/roles.vue')),
        meta: { admin: true }
    },
    {
        path: 'permissions',
        component: wrapRuntimeImport(() => import('./screens/permissions.vue')),
        meta: { admin: true }
    },
    {
        path: 'idp',
        component: wrapRuntimeImport(() => import('./screens/idp.vue')),
        meta: { admin: true }
    }
];
