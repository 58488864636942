<template>
    <ul id="user-menu">
        <!-- <li @click="openSettings">Settings</li> -->
        <li v-if="canChangePassword" @click="changePassword">Change Password</li>
        <li v-if="canEnrollFingerprint" @click="manageFingerprints">Manage Fingerprints</li>
        <li v-if="isTenantAdmin" @click="openAdmin">Admin</li>
        <li class="separator"></li>
        <li v-if="hasAdditionalTenants" @click="openTenantSwitcher">Switch Tenant</li>
        <li @click="logout">Logout</li>
    </ul>
</template>

<script lang="ts" setup>
import { presentOverlay } from '@signal24/vue-foundation';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { GlobalState } from '@/global';
import MEnrollFingerprint from '@/modules/auth/exports/m-enroll-fingerprint.vue';
import MFingerprints from '@/modules/auth/exports/m-fingerprints.vue';
import MSetPassword from '@/modules/auth/exports/m-set-password.vue';
import TenantSwitcherModal from '@/modules/auth/exports/m-tenant-switcher.vue';
import { hasPermission } from '@/shared/helpers/permissions.helpers';

defineExpose({ openTenantSwitcher });

const router = useRouter();

const isTenantAdmin = computed(() => GlobalState.tenant.value?.isAdmin);
const hasAdditionalTenants = computed(() => (GlobalState.user.value?.tenants.length ?? 0) > 1);
const canChangePassword = computed(
    () => (GlobalState.isInstall || GlobalState.user.value?.authMech?.includes('password')) && hasPermission('core', 'changePassword')
);
const canEnrollFingerprint = computed(
    () => GlobalState.isInstall && GlobalState.installCapabilities.value?.fingerprintReader && hasPermission('core', 'manageFingerprints')
);

// function openSettings() {
//     popover.value?.close();
// }

function openAdmin() {
    if (router.currentRoute.value.path.startsWith('/admin')) return;
    router.push({ path: '/admin' });
}

function openTenantSwitcher() {
    presentOverlay(TenantSwitcherModal, {});
}

function changePassword() {
    presentOverlay(MSetPassword, {});
}

function manageFingerprints() {
    presentOverlay(MFingerprints, {});
}

function logout() {
    router.replace({ name: 'logout' });
}
</script>

<style lang="scss" scoped>
#user-menu {
    @apply py-1;

    li:not(.separator) {
        @apply hover:bg-blue-600 hover:text-white p-3 cursor-pointer;
    }

    li.separator {
        @apply border-t border-gray-300 my-1;

        &:first-child {
            @apply hidden;
        }
    }
}
</style>
