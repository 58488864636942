import type { RouteRecordRaw } from 'vue-router';

import { wrapRuntimeImport } from '@/shared/helpers/async.helpers';

import { AdminIamRoutes } from './subapps/iam/routes';
import { AdminSalesRoutes } from './subapps/sales/routes';
import { AdminTalkRoutes } from './subapps/talk/routes';
import Welcome from './welcome.vue';

export const AdminRoutes: RouteRecordRaw[] = [
    {
        path: '',
        component: Welcome,
        meta: { admin: true }
    },
    {
        path: 'iam',
        component: wrapRuntimeImport(() => import('./subapps/iam/iam.vue')),
        meta: { admin: true },
        children: AdminIamRoutes
    },
    {
        path: 'sales',
        component: wrapRuntimeImport(() => import('./subapps/sales/sales.vue')),
        meta: { admin: true },
        children: AdminSalesRoutes
    },
    {
        path: 'talk',
        component: wrapRuntimeImport(() => import('./subapps/talk/talk.vue')),
        meta: { admin: true },
        children: AdminTalkRoutes
    }
];
