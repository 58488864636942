<template>
    <div id="email-login-form">
        <div v-if="error" class="error-message" :class="errorType" :innerText="error"></div>

        <Loader v-if="isProcessing"></Loader>

        <form v-else ref="form" @submit.prevent="login">
            <input v-model="email" v-autofocus="!enablePassword" type="email" placeholder="e-mail address" />
            <input v-if="enablePassword" v-model="password" v-autofocus type="password" placeholder="password" />
            <button class="primary">Continue</button>

            <template v-if="!enablePassword">
                <button type="button" class="sso sso-microsoft" @click="ssoLogin('azure')">
                    <img src="/assets/login-ms.svg" /> Sign in with Microsoft
                </button>
                <button type="button" class="sso sso-google" @click="ssoLogin('google')">
                    <img src="/assets/login-google.svg" /> Sign in with Google
                </button>
            </template>
        </form>
    </div>
</template>

<script lang="ts" setup>
import { formatError, handleError, maskForm, unmaskForm } from '@signal24/vue-foundation';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import { LoginApi } from '@/openapi-clients-generated/auth';
import Loader from '@/shared/components/loader.vue';
import { Auth } from '@/shared/services/auth.service';
import { dataFrom } from '@signal24/openapi-client-codegen/browser';

const router = useRouter();

const form = ref<HTMLFormElement>();
const error = ref<null | string>(null);
const errorType = ref<string | null>();
const email = ref('');
const password = ref('');

const preferredTenantId = Auth.lastTenantId ?? undefined;
const callbackUrl = `${location.origin}/login/callback`;
const isCallbackUrl = router.currentRoute.value.name === 'login:callback';
const isProcessing = ref(isCallbackUrl);
const enablePassword = ref(false);

async function login() {
    if (!email.value.trim().length) {
        return;
    }
    if (enablePassword.value && !password.value.length) {
        return;
    }

    maskForm(form.value!);

    try {
        const intendedUrl = String(router.currentRoute.value.query.intendedUrl ?? '/');

        if (enablePassword.value) {
            const { data } = await LoginApi.postLoginPasswordLogin<true>({
                body: { email: email.value, password: password.value }
            });
            await Auth.processLoginResponse(data);
            router.replace(intendedUrl ?? '/');
        } else {
            const response = dataFrom(
                await LoginApi.postLoginLogin({
                    body: { email: email.value, redirectUrl: callbackUrl, intendedUrl }
                })
            );
            if (response.authorizeUrl) {
                location.href = response.authorizeUrl;
            } else {
                unmaskForm(form.value!);
                enablePassword.value = true;
            }
        }
    } catch (err) {
        unmaskForm(form.value!);
        handleLoginError(err);
    }
}

async function ssoLogin(provider: 'azure' | 'google') {
    try {
        isProcessing.value = true;
        const intendedUrl = String(router.currentRoute.value.query.intendedUrl ?? '/');
        const { data } = await LoginApi.postLoginLogin({
            body: {
                provider,
                intendedUrl,
                redirectUrl: callbackUrl
            }
        });
        location.href = data!.authorizeUrl!;
    } catch (err) {
        handleLoginError(err);
    }
}

async function handleSSOCallback() {
    const callbackParams = location.search || location.hash?.replace(/^#/, '') || '';
    if (!callbackParams) {
        return handleLoginError('Missing callback parameters', true);
    }

    // remove the code from the URL so it doesn't get re-used if the user refreshes the page
    // we have issues replacing the URL during mount, so we'll defer the rest for a moment
    setTimeout(() => window.history.replaceState(null, '', '/login/callback'), 0);

    const urlParams = new URLSearchParams(callbackParams);
    if (urlParams.has('error')) {
        return handleLoginError(`SSO error: ${urlParams.get('error')}: ${urlParams.get('error_description')}`, true);
    }

    if (!urlParams.has('code')) {
        return handleLoginError('Missing code parameter', true);
    }

    const code = urlParams.get('code')!;
    const state = urlParams.get('state')!;

    // clear any existing session so that it's not included in the login request
    Auth.clearUserSession();

    try {
        const { data } = await LoginApi.postLoginSsoCallback<true>({ body: { redirectUrl: callbackUrl, code, state, preferredTenantId } });
        await Auth.processLoginResponse(data);
        router.replace(data.intendedUrl ?? '/');
    } catch (err) {
        return handleLoginError(err);
    }
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
function handleLoginError(err: any, isCritical?: boolean) {
    if (err instanceof Error || isCritical) {
        handleError(err);
        error.value = String(err).includes('invalid_credentials')
            ? 'The e-mail address or password you provided is incorrect. Please try again. If you need further assistance, please contact support@zyno.app'
            : formatError(err);
    } else {
        error.value = err;
    }

    errorType.value = null;
    isProcessing.value = false;
}

const sessionLogoutCause = Auth.logoutCauseOnce;
if (sessionLogoutCause) {
    error.value = sessionLogoutCause;
    errorType.value = 'warning';
}

onMounted(() => {
    if (isCallbackUrl) {
        handleSSOCallback();
    }
});
</script>

<style lang="scss" scoped>
#email-login-form {
    @apply max-w-xs w-screen;
}

form {
    @apply flex flex-col gap-2;
}

.sso {
    @apply bg-white border-gray-300 hover:bg-gray-200 flex gap-2;

    img {
        @apply max-w-6;
    }
}
</style>
