<template>
    <VfModal id="m-auth-setPassword" ref="modal">
        <template #header>
            {{ title }}
        </template>

        <p>Please set a PIN or password that is at least 8 digits or characters in length.</p>

        <div class="input-wrapper">
            <i class="fa fa-lock"></i>
            <input v-model="newPassword" v-autofocus type="password" placeholder="new PIN / password" autocomplete="off" data-1p-ignore required />
        </div>

        <div class="input-wrapper">
            <i class="fa fa-lock"></i>
            <input v-model="confirmPassword" type="password" placeholder="confirm PIN / password" autocomplete="off" data-1p-ignore required />
        </div>

        <template #footer>
            <button type="button" @click="callback()">Cancel</button>
            <button class="primary" @click="save">Save</button>
        </template>
    </VfModal>
</template>

<script lang="ts" setup>
import { UserError, VfModal, showAlert, vfModalRef } from '@signal24/vue-foundation';
import { computed, ref } from 'vue';

import { GlobalState } from '@/global';
import { IdentityApi } from '@/openapi-clients-generated/auth';
import { withWait } from '@/shared/helpers/request.helpers';

const props = defineProps<{
    accountId?: string;
    callback: () => void;
}>();

const modal = vfModalRef();
const title = computed(() => (props.accountId ? 'Set Password' : 'Change Password'));

const newPassword = ref('');
const confirmPassword = ref('');

function save() {
    withWait(
        () => modal.value!.mask(),
        async () => {
            if (newPassword.value.length < 6) throw new UserError('Password must be at least 8 characters long.');
            if (newPassword.value !== confirmPassword.value) throw new UserError('Passwords do not match.');

            modal.value!.mask();

            await IdentityApi.putIdentitySetPassword({
                body: {
                    accountId: props.accountId ?? GlobalState.user.value?.id,
                    password: newPassword.value
                }
            });
            await showAlert(title.value, `${props.accountId ? 'The' : 'Your'} password has been ${props.accountId ? 'set' : 'changed'}.`);
            props.callback();
        }
    );
}
</script>

<style lang="scss">
#m-auth-setPassword {
    .vf-modal-content {
        @apply w-96;
    }
}
</style>

<style lang="scss" scoped>
p {
    @apply mb-4;
}

.input-wrapper {
    @apply mt-1;

    input {
        @apply w-full;
    }
}
</style>
