import Color from 'color';

import { hashCode } from './string.helpers';

interface RGB {
    r: number;
    g: number;
    b: number;
}

export function calculateMidpoint(color1: string, color2: string) {
    const color1C = Color(color1);
    const color2C = Color(color2);

    return Color.rgb((color1C.red() + color2C.red()) / 2, (color1C.green() + color2C.green()) / 2, (color1C.blue() + color2C.blue()) / 2).string();
}

export function addBrightness(color: string, brightness: number) {
    return Color(color)
        .lighten(brightness / 100)
        .string();
}

export function intToRGB(i: number): RGB {
    const c = (i & 0x00ffffff).toString(16).toUpperCase();

    const hex = '00000'.substring(0, 6 - c.length) + c;

    return {
        r: Number.parseInt(hex.slice(0, 2), 16),
        g: Number.parseInt(hex.slice(2, 4), 16),
        b: Number.parseInt(hex.slice(4, 6), 16)
    };
}

export function hslToRgb(h: number, s: number, l: number): RGB {
    let r: number;
    let g: number;
    let b: number;

    if (s === 0) {
        r = g = b = l; // achromatic
    } else {
        const hue2rgb = function hue2rgb(p: number, q: number, t: number): number {
            if (t < 0) t += 1;
            if (t > 1) t -= 1;
            if (t < 1 / 6) return p + (q - p) * 6 * t;
            if (t < 1 / 2) return q;
            if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
            return p;
        };

        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
    }

    return { r: Math.round(r * 255), g: Math.round(g * 255), b: Math.round(b * 255) };
}

export function computeColorFromString(input: string) {
    const hash = hashCode(input);
    const hue = (hash % 360) / 360;
    const saturation = 0.5;
    const lightness = 0.3;
    const rgb = hslToRgb(hue, saturation, lightness);
    return rgb;
}

export function getTextColor(bgColor: RGB): string {
    // The constants 0.299, 0.587, and 0.114 represent the different degrees to which each of the
    // primary (RGB) colors affects human perception of the overall brightness of a color.
    // Note that they sum to 1.
    const luminance = 0.299 * bgColor.r + 0.587 * bgColor.g + 0.114 * bgColor.b;
    return luminance > 186 ? 'black' : 'white';
}
