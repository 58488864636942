import { formatError } from '@signal24/vue-foundation';
import { type Dictionary, keyBy } from 'lodash';
import { type Ref, computed, ref } from 'vue';

import { RolesApi } from '@/openapi-clients-generated/auth';
import {
    CoreLocationsApi,
    FormsApi,
    InventoryCategoriesApi,
    InventoryVendorsApi,
    SalesConfigProductCategoriesApi,
    SalesConfigTaxesApi,
    SalesFinancialAccountsApi
} from '@/openapi-clients-generated/suite';
import { AccessApi, ExtensionsApi, NumbersApi } from '@/openapi-clients-generated/talk';
import { type OpenApiDataType, dataFrom } from '@signal24/openapi-client-codegen/browser';

const CacheLoaders = {
    authRoles: () => RolesApi.getRolesList(),
    coreLocations: () => CoreLocationsApi.getCoreLocationsIndex(),
    forms: () => FormsApi.getFormsListForms({}),
    inventoryCategories: () => InventoryCategoriesApi.getInventoryCategoriesIndex(),
    inventoryVendors: () => InventoryVendorsApi.getInventoryVendorsIndex(),
    salesCategories: () => SalesConfigProductCategoriesApi.getSalesConfigProductCategoriesIndex(),
    salesTaxes: () => SalesConfigTaxesApi.getSalesConfigTaxesIndex(),
    salesFinancialAccounts: () => SalesFinancialAccountsApi.getSalesFinancialAccountsIndex(),
    talkAccess: () => AccessApi.getAccessGetComposite(),
    talkNumbers: () => NumbersApi.getNumbersListNumbers(),
    talkExtensions: () => ExtensionsApi.getExtensionsListExtensions()
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export type ICacheKey = keyof typeof CacheLoaders;

export type CacheDataType<T extends ICacheKey> = OpenApiDataType<Awaited<ReturnType<(typeof CacheLoaders)[T]>>>;

type TypeWithId = {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    [K in ICacheKey]: CacheDataType<K> extends any[] ? (CacheDataType<K>[number] extends { id: any } ? K : never) : never;
}[ICacheKey];

export interface AsyncRef<T> extends Ref<T> {
    reload?: () => void;
    loadError?: string;
    loadPromise?: Promise<void>;
}
export function asyncRef<T>(value: T): AsyncRef<T>;
export function asyncRef<T>(): AsyncRef<T | undefined>;
export function asyncRef<T>(value?: T): AsyncRef<T | undefined> {
    return ref(value) as AsyncRef<T>;
}

export class DataCache {
    static refCache: { [K in ICacheKey]?: AsyncRef<CacheDataType<K> | undefined> } = {};

    static getRef<T extends ICacheKey>(key: T): AsyncRef<CacheDataType<T> | undefined> {
        if (!this.refCache[key]) {
            const newRef = ref<CacheDataType<T> | undefined>() as AsyncRef<CacheDataType<T> | undefined>;
            const load = () => {
                return CacheLoaders[key]().then(
                    result => {
                        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                        newRef.value = dataFrom(result as any) as CacheDataType<T>;
                    },
                    err => {
                        newRef.loadError = formatError(err);
                    }
                );
            };
            newRef.loadPromise = load();
            newRef.reload = () => {
                newRef.value = undefined;
                newRef.loadError = undefined;
                newRef.loadPromise = load();
            };
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            this.refCache[key] = newRef as any; // ts issue
        }
        return this.refCache[key]!;
    }

    static getMapById<T extends TypeWithId>(key: T) {
        const ref = this.getRef(key);
        return computed(() => keyBy(ref.value, 'id') as Dictionary<CacheDataType<T>[number]>);
    }

    static reloadCaches() {
        for (const [key, ref] of Object.entries(this.refCache)) {
            ref.reload?.();
        }
    }
}

export class TransientPreferenceCache {
    static get<T>(key: string) {
        const value = sessionStorage.getItem(`zs:tpref:${key}`);
        return value ? (JSON.parse(value) as T) : undefined;
    }

    static set<T>(key: string, value: T) {
        sessionStorage.setItem(`zs:tpref:${key}`, JSON.stringify(value));
    }
}
