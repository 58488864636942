<template>
    <div id="desktop">
        <div v-if="needLogin">
            <button class="primary" @click="launchLogin">Sign into Zyno</button>
        </div>

        <template v-else>
            <Loader size="2xl" />
            <div class="status">{{ status }}</div>
        </template>
    </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import { GlobalState } from '@/global';
import Loader from '@/shared/components/loader.vue';
import { Auth } from '@/shared/services/auth.service';

import { InstallsApi } from '@/openapi-clients-generated/auth';
import { OpenApiError, dataFrom } from '@signal24/openapi-client-codegen/browser';
import { DesktopBridge } from '../helpers/bridge';

const props = defineProps<{
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    showError: (err?: any) => void;
}>();

const router = useRouter();

const status = ref('Checking in...');
const needLogin = ref(false);

function launchLogin() {
    status.value = 'Waiting for sign in...';
    needLogin.value = false;
    DesktopBridge.bridgeFunctions?.launchRegistration();
}

async function checkIn() {
    // initialize the bridge before performing any other operation
    await DesktopBridge.init();

    // this route should only be accessed by Zyno Desktop w/ WebView2
    if (!DesktopBridge.isWithinZynoDesktop()) {
        return props.showError();
    }

    const handshake = await DesktopBridge.bridgeFunctions?.generateHandshake();
    if (!handshake) {
        return props.showError();
    }

    try {
        const response = dataFrom(
            await InstallsApi.postInstallsDetectInstall({
                body: { handshake }
            })
        );

        Auth.setInstall({ id: response.id });
        Auth.setTenant({ id: response.tenantId, name: response.tenantName, isAdmin: false });

        const fingerprintReader = await DesktopBridge.bridgeFunctions!.hasFingerprintReader();
        GlobalState.installCapabilities.value = { fingerprintReader };
    } catch (err) {
        if (err instanceof OpenApiError) {
            if (err.response.status === 401) {
                needLogin.value = true;
                return;
            }
        }
        return props.showError(err);
    }

    // run post-registration operations
    await DesktopBridge.bridgeFunctions?.performPostRegistrationTasks();

    // continue as a normal web app
    router.replace('/');
}

onMounted(checkIn);
</script>

<style lang="scss" scoped>
#desktop {
    @apply flex-1 flex flex-col items-center justify-center gap-8;
}

.status {
    @apply text-white text-xl font-semibold;
}
</style>
