<template>
    <div id="logo">
        <div class="fade top"></div>
        <div class="title" @click="launchSales">
            <div>Zyno</div>
            <div class="product-wrapper">
                <strong>Suite</strong>
                <div class="product-titles" :class="{ animating }" @transitionend="onTransitionEnd">
                    <strong>Suite</strong>
                    <strong v-for="product in products" :key="product">{{ product }}</strong>
                    <strong>Suite</strong>
                </div>
            </div>
        </div>
        <div class="fade bottom"></div>
    </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';

const products = ['IT', 'Access', 'Display', 'Forms', 'Chat', 'Talk', 'CRM', 'POS', 'Inventory', 'Booking', 'Media', 'Workforce'];

const animating = ref(false);

function launchSales() {
    window.open('https://zynosuite.com', '_blank');
}

function scheduleTransition() {
    setTimeout(beginTransition, 1500);
}

function beginTransition() {
    animating.value = true;
}

function onTransitionEnd() {
    if (!animating.value) return;
    animating.value = false;
    scheduleTransition();
}

onMounted(scheduleTransition);
</script>

<style lang="scss" scoped>
#logo {
    @apply relative overflow-y-clip;
}

.fade {
    @apply absolute w-full h-1.5 z-10;

    &.top {
        @apply top-0;
        background: linear-gradient(to top, rgba(30, 41, 59, 0), rgba(30, 41, 59, 1));
    }

    &.bottom {
        @apply bottom-0;
        background: linear-gradient(to bottom, rgba(30, 41, 59, 0), rgba(30, 41, 59, 1));
    }
}

.title {
    @apply relative py-1.5 flex cursor-pointer;

    * {
        @apply text-white text-3xl;
        font-weight: 200;
    }

    strong {
        @apply text-3xl;
        font-weight: 500;
    }
}

.product-wrapper {
    @apply relative;

    > strong {
        @apply invisible;
    }

    .product-titles {
        @apply flex flex-col absolute top-0 left-0;
        transform: translateY(0);

        &.animating {
            transform: translateY(calc(-100% + 2.25rem));
            transition: all 8s linear;
        }
    }
}
</style>
