import type { RouteRecordRaw } from 'vue-router';

import { wrapRuntimeImport } from '@/shared/helpers/async.helpers';

export const AdminTalkRoutes: RouteRecordRaw[] = [
    {
        path: '',
        redirect: '/admin/talk/numbers'
    },
    {
        path: 'numbers',
        component: wrapRuntimeImport(() => import('./screens/numbers.vue')),
        meta: { admin: true }
    }
];
