import type Tracker from '@openreplay/tracker';
import { uuid } from '@signal24/vue-foundation';

import type { IAccountBasic } from '@/openapi-clients-generated/auth';

export class OpenReplay {
    static tracker?: Tracker;
    static sessionId?: string;

    static async start(user?: IAccountBasic) {
        if (!this.tracker) return;
        await this.tracker.start({
            userID: user?.email ?? 'anonymous',
            forceNew: true,
            sessionHash: uuid()
        });
        this.sessionId = this.tracker.getSessionID() ?? undefined;
    }

    static stop() {
        this.tracker?.stop();
        this.sessionId = undefined;
    }

    static get sessionUrl() {
        return this.tracker?.getSessionURL();
    }
}
