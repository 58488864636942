import type { RouteRecordRaw } from 'vue-router';

import { wrapRuntimeImport } from '@/shared/helpers/async.helpers';
import { makePermissionMeta } from '@/shared/helpers/permissions.helpers';

export const SalesConfigRoutes: RouteRecordRaw[] = [
    {
        path: 'products',
        component: wrapRuntimeImport(() => import('./screens/products.vue')),
        meta: makePermissionMeta('sales', 'productsView')
    }
];
