import type { RouteRecordRaw } from 'vue-router';

import { wrapRuntimeImport } from '@/shared/helpers/async.helpers';
import { makePermissionMeta } from '@/shared/helpers/permissions.helpers';

export const InventoryRoutes: RouteRecordRaw[] = [
    {
        path: '',
        component: wrapRuntimeImport(() => import('./screens/list.vue')),
        meta: makePermissionMeta('inventory')
    },
    {
        path: 'perform',
        component: wrapRuntimeImport(() => import('./screens/perform-inventory.vue')),
        meta: makePermissionMeta('inventory', 'editQty')
    }
];
